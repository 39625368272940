import { LanguageType, StringsObjectType } from './types'

export const getStrings = (language: LanguageType): StringsObjectType => {
  if (language === 'english') {
    return englishStrings
  }

  return romanianStrings
}

const englishStrings: StringsObjectType = {
  resultsTitle: 'Results',
  results: [
    {
      text: 'Research report on automated algorithms for early detection of cyber vulnerabilities',
      status: 'Completed',
    },
    {
      text: 'Research report on required algorithms for increasing the processed data volume using NLP and evaluating extracted data using ML',
      status: 'Completed',
    },
    {
      text: 'Research report on open data sources and formats required for Yggdrasil module implementation',
      status: 'Completed',
    },
    {
      text: 'Technical specifications for laboratory implementation of the open data analysis and processing for Yggdrasil',
      status: 'Completed',
    },
    {
      text: 'Analysis report of the preliminary results obtained with the Yggdrasil prototype',
      status: 'Completed',
    },
    {
      text: 'Recommendations report for the inclusion of new potential data sources.',
      status: 'Completed',
    },
    {
      text: 'Laboratory development report of the Yggdrasil prototype',
      status: 'Completed',
    },
    {
      text: 'Development report of interfacing and integrating of the Yggdrasil prototype with the CODA Footprint platform',
      status: 'Completed',
    },
    {
      text: 'Three (3) articles public-private co-publications disseminated in significant conferences for the field',
      status: 'Completed',
    },
  ],
  visionTitle: 'Vision',
  vision: [
    'The intensification of the number and types of cyber-attacks in recent years appears as a result of the vulnerabilities created by an accelerated digitization process. This project proposes an automatic system for the early detection of cyber vulnerabilities and threats based on open source data: OSINT (Open-source intelligence), called Yggdrasil (name inspired by Norse mythology), which responds to the increasing number and complexity of cyber-attacks.',
    'The innovative character of this project is given by the real-time use of social media data and other relevant open online sources to identify public references that present new types of cyber threats or types of zero-day attacks, where the reaction time is vital for limiting possible damage.',
    'The Yggdrasil project aims to improve cyber security services at the national and international levels by reducing the chance of exploiting emerging vulnerabilities.',
  ],
  goalsTitle: 'Goals',
  goals: [
    'The project team aims to develop within 18 months an automated system for early detection of vulnerabilities and cyber-attacks based on open source data (OSINT), competitive at national and international level.',
  ],
  partnersTitle: 'Partners',
  partners: [
    { text: 'CODA Intelligence' },
    { text: 'University Politehnica of Bucharest' },
  ],
  phasesTitle: 'Project Phases',
  phases: [
    {
      title: 'Research and design of algorithms',
      acts: [
        {
          task: 'Research and design of automated algorithms for early detection of cyber vulnerabilities',
          result:
            'Research report on automated algorithms for early detection of cyber vulnerabilities',
          partner: 'CODA',
        },
        {
          task: 'Research and design of algorithms necessary to increase the volume of data processed using NLP and to evaluate the extracted data using ML type techniques',
          result:
            'Research report on required algorithms for increasing the processed data volume using NLP and evaluating extracted data using ML',
          partner: 'UPB',
        },
      ],
    },
    {
      title: 'Analysis of data sources and definition of specifications',
      acts: [
        {
          task: 'Analysis, definition, and preparation of OSINT data sources for Yggdrasil',
          result:
            'Research report on open data sources and formats required for Yggdrasil module implementation',
          partner: 'UPB',
        },
        {
          task: 'Defining the technical specifications for the laboratory implementation of the OSINT data analysis and processing module',
          result:
            'Technical specifications for laboratory implementation of the open data analysis and processing for Yggdrasil',
          partner: 'CODA',
        },
      ],
    },
    {
      title:
        'YGGDRASIL Development and Implementation in the CODA Footprint Platform',
      acts: [
        {
          task: 'Analysis of preliminary results and optimization of processing flows, as well as consideration of new potential data sources',
          result:
            'Analysis report of the preliminary results obtained with the Yggdrasil prototype. Recommendations report for the inclusion of new potential data sources',
          partner: 'CODA',
        },
        {
          task: 'Experimental laboratory development of the Yggdrasil prototype',
          result: 'Laboratory development report of the Yggdrasil prototype',
          partner: 'UPB',
        },
        {
          task: 'Integration of the Yggdrasil prototype as a module in the CODA Footprint platform',
          result:
            'Development report of interfacing and integrating of the Yggdrasil prototype with the CODA Footprint platform',
          partner: 'CODA',
        },
      ],
    },
  ],
}

const romanianStrings: StringsObjectType = {
  resultsTitle: 'Rezultate',
  results: [
    {
      text: 'Raport de cercetare asupra algoritmilor automați pentru detectarea timpurie a vulnerabilităților cibernetice',
      status: 'Realizat',
    },
    {
      text: 'Raport de cercetare asupra algoritmilor necesari pentru creșterea volumului de date procesat folosind NLP si a evaluării datelor extrase folosind tehnici de tip ML',
      status: 'Realizat',
    },
    {
      text: 'Raport de cercetare privind sursele si formatul de date deschise necesare implementării modulului Yggdrasil',
      status: 'Realizat',
    },
    {
      text: 'Specificații tehnice pentru implementarea în laborator a modulului de analiza și procesare a datelor deschise pentru Yggdrasil',
      status: 'Realizat',
    },
    {
      text: 'Raport de analiză a rezultatelor preliminare obținute cu prototipul Yggdrasil',
      status: 'Realizat',
    },
    {
      text: 'Raport de recomandări pentru includerea de noi potențiale surse de date',
      status: 'Realizat',
    },
    {
      text: 'Raport de dezvoltare în laborator a prototipului de laborator Yggdrasil',
      status: 'Realizat',
    },
    {
      text: 'Raport de dezvoltare a interfațării și integrării prototipului Yggdrasil în platforma CODA Footprint',
      status: 'Realizat',
    },
    {
      text: 'Trei (3) articole co-publicații public-private diseminate în cadrul unor conferințe semnificative pentru domeniu',
      status: 'Realizat',
    },
  ],
  visionTitle: 'Viziune',
  vision: [
    'Intensificarea numărului și tipurilor de atacuri cibernetice din ultimii ani apare ca rezultat al vulnerabilităților create de un proces accelerat de digitalizare. Proiectul de față propune un sistem automat pentru detectarea timpurie a vulnerabilităților și amenințărilor cibernetice bazat pe date din surse deschise: OSINT (Open-source intelligence), denumit Yggdrasil (nume inspirat din mitologia nordică), care să răspundă creșterii numărului și a complexității atacurilor cibernetice.',
    'Caracterul inovativ al acestui proiect este dat de utilizarea în timp real a datelor social media și a altor surse relevante online deschise pentru identificarea referințelor publice care prezintă noi tipuri de amenințări cibernetice sau tipuri de atacuri zero-day, unde timpul de reacție este vital pentru limitarea posibilelor pagube.',
    'Proiectul Yggdrasil vizează îmbunătățirea serviciilor de securitate cibernetică la nivel național și internațional prin reducerea șansei de exploatare a vulnerabilităților emergente.',
  ],
  goalsTitle: 'Obiective',
  goals: [
    'Echipa de proiect își propune dezvoltarea în termen de 18 luni a unui sistem automat pentru detectarea timpurie a vulnerabilităților și atacurilor cibernetice pe baza datelor din surse deschise (OSINT), competitiv la nivel național și internațional.',
    'Obiectivele specifice ale proiectului presupun:',
    '(i) implementarea funcționalităților cheie aferente unui sistem de detectare timpurie a vulnerabilităților cibernetice,',
    '(ii) optimizarea prototipului existent pentru atingerea nivelului de maturitate tehnologică TRL 6 și',
    '(iii) integrarea cu sistemul CODA Footprint care vizează detecția vulnerabilităților cibernetice cunoscute.',
  ],
  partnersTitle: 'Parteneri',
  partners: [
    { text: 'CODA Intelligence' },
    { text: 'Universitatea Politehnica din București' },
  ],
  phasesTitle: 'Etapele Proiectului',
  phases: [
    {
      title: 'Cercetarea și proiectarea algoritmilor',
      acts: [
        {
          task: 'Cercetarea și proiectarea algoritmilor automați pentru detectarea timpurie a vulnerabilităților cibernetice',
          result:
            'Raport de cercetare asupra algoritmilor automați pentru detectarea timpurie a vulnerabilităților cibernetice',
          partner: 'CODA',
        },
        {
          task: 'Cercetarea și proiectarea algoritmilor necesari pentru creșterea volumului de date procesat folosind NLP și a evaluării datelor extrase folosind tehnici de tip ML',
          result:
            'Raport de cercetare asupra algoritmilor necesari pentru creșterea volumului de date procesat folosind NLP și a evalușrii datelor extrase folosind tehnici de tip ML',
          partner: 'UPB',
        },
      ],
    },
    {
      title: 'Analiza surselor de date și definirea specificațiilor',
      acts: [
        {
          task: 'Analiza, definirea și pregatirea surselor de date de tip OSINT pentru Yggdrasil ',
          result:
            'Raport de cercetare privind sursele și formatul de date deschise necesare implementării modulului Yggdrasil',
          partner: 'UPB',
        },
        {
          task: 'Definirea specificațiilor tehnice pentru implementarea în laborator a modulului de analiza și procesare a datelor OSINT',
          result:
            'Specificații tehnice pentru implementarea în laborator a modulului de analiza și procesare a datelor deshise pentru Yggdrasil',
          partner: 'CODA',
        },
      ],
    },
    {
      title:
        'Dezvoltarea YGGDRASIL și implementarea în platforma CODA Footprint',
      acts: [
        {
          task: 'Analiza rezultatelor preliminare și optimizarea fluxurilor de procesare, precum și considerarea de noi potențiale surse de date',
          result:
            'Raport de analiză a rezultatelor preliminare obținute cu prototipul Yggdrasil. Raport de recomandări pentru includerea de noi potențiale surse de date',
          partner: 'CODA',
        },
        {
          task: 'Dezvoltare experimentală în laborator a prototipului Yggdrasil',
          result: 'Raport de dezvoltare în laborator a prototipului Yggdrasil',
          partner: 'UPB',
        },
        {
          task: 'Integrarea prototipului Yggdrasil ca modul în platforma CODA Footprint ',
          result:
            'Raport de dezvoltare a interfațării și integrării prototipului Yggdrasil în platforma CODA Footprint',
          partner: 'CODA',
        },
      ],
    },
  ],
}
