import * as React from 'react'

import { StringsObjectType } from 'data/types'

import './index.scss'

export interface IMenuProps {
  stringsObject: StringsObjectType
  onItemClick: (id: string) => void
}

export default function Menu(props: IMenuProps) {
  return (
    <div className='Menu'>
      <p onClick={() => props.onItemClick('vision')}>{props.stringsObject.visionTitle}</p>
      <p onClick={() => props.onItemClick('goals')}>{props.stringsObject.goalsTitle}</p>
      <p onClick={() => props.onItemClick('results')}>{props.stringsObject.resultsTitle}</p>
      <p onClick={() => props.onItemClick('phases')}>{props.stringsObject.phasesTitle}</p>
      <p onClick={() => props.onItemClick('dissemination')}>Diseminare</p>
    </div>
  )
}
