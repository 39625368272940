import './index.scss'

import * as React from 'react'

import VulnerabilityManager from './assets/VulnerabilityManager.png'
import OSINT from './assets/OSINT.png'

export default function Prototype() {
  return (
    <div className='Prototype'>
      <div className='Story'>
        <p>
          Etapa III a proiectului a permis colectarea de feedback referitor la
          utilizabilitatea prototipului interfeței utilizatorului și
          implementarea acesteia urmărind direcțiile de îmbunătățire inferate.
          În imaginile de mai jos, sunt prezentate ecranul fluxului de știri
          (dreapta) alături de ecranul afișării și editării vulnerabilităților
          cibernetice (stânga) după implementarea feedback-ului.
        </p>
        <div className='Images'>
          <img src={OSINT} alt='' />
          <img src={VulnerabilityManager} alt='' />
        </div>
      </div>
    </div>
  )
}
