import './index.scss'

import * as React from 'react'

import coda from './assets/coda.svg'
import upb from './assets/upb.png'

export default function GeneralInformation() {
  return (
    <div className='GeneralInformation'>
      <p>
        <b>Tip proiect</b>: Proiect de transfer la operatorul economic (PTE)
      </p>
      <p>
        <b>Contract nr. 2PTE / 2020</b>
      </p>
      <p>
        <b>Durata contractului</b>: 18 luni
      </p>
      <p>
        <b>Valoarea proiectului</b>: 1.365.475,00 lei din care
      </p>
      <ul>
        <li>
          <b>Valoarea de la bugetul de stat</b>: 1.181.000,00 lei
        </li>
        <li>
          <b>Valoarea cofinanțare</b>: 184.475,00 lei
        </li>
      </ul>
      <p>
        <b>Autoritatea contractantă</b>: UEFISCDI
      </p>
      <div className='withPhoto'>
        <p>
          <b>Coordonator</b>:{' '}
          <a
            href='https://www.codaintelligence.com'
            rel='noopener noreferrer'
            target='_blank'>
            CODA Intelligence{' '}
          </a>
        </p>
        <span>
          <img src={coda} alt='' />
        </span>
      </div>
      <div className='withPhoto'>
        <p>
          <b>Parteneri</b>:{' '}
          <a
            href='https://www.upb.ro'
            rel='noopener noreferrer'
            target='_blank'>
            UNIVERSITATEA POLITEHNICA DIN BUCUREȘTI
          </a>
        </p>
        <span>
          <img src={upb} alt='' />
        </span>
      </div>
    </div>
  )
}
