import * as React from 'react'

import logoEN from './assets/uefiscdiEN.svg'
import logoRO from './assets/uefiscdiRO.svg'

import { LanguageType } from 'data/types'

import './index.scss'

export interface IHeaderProps {
  language: LanguageType
}

export default function Header(props: IHeaderProps) {
  return (
    <div className='Header'>
      {props.language === 'english'
        ? <img src={logoEN} alt='' />
        : <img src={logoRO} alt='' />}
    </div>
  )
}
