import * as React from 'react'

import { PhaseType, ActType } from 'data/types'

import './index.scss'

export interface IPhasesProps {
  phases: PhaseType[]
}

export interface IPhaseProps {
  index: number
  title: string
  acts: any
}

export default function Phases(props: IPhasesProps) {
  return (
    <div className='Phases'>
      {props.phases.map((phase: PhaseType, index: number) => (
        <Phase key={index} index={index + 1} title={phase.title} acts={phase.acts} />
      ))}
    </div>
  )
}

const Phase = (props: IPhaseProps) => {
  return (
    <div className='Phase'>
      <h2>{props.index}. {props.title}</h2>
      {
        props.acts.map((act: ActType, actIndex: number) => (
          <div className='Act'>
            <p>{`Act. ${props.index}.${actIndex + 1}`}</p>
            <p>{act.task}</p>
            <p>{act.result}</p>
            <p>{act.partner}</p>
          </div>
        ))
      }
    </div>
  )
}
