import './index.scss'

import * as React from 'react'

import Bert from './assets/BERT.png'
import WordCloud from './assets/WordCloud.png'
import Efficiency from './assets/Efficiency.png'

export default function Sequence() {
  return (
    <div className='Sequence'>
      <div className='Story'>
        <p>
          În urma realizării activității etapei I a proiectului a fost
          dezvoltată o secvență de procesare la nivel pilot pentru detectarea
          timpurie a vulnerabilităților din articole de specialitate din
          domeniul securității.
        </p>
        <p>
          Secvența de procesare la nivel pilot are la bază utilizarea unui model
          de limbă de tip BERT care permite procesarea textului din articole de
          specialitate din domeniul securității cibernetice:
        </p>
        <img alt='' src={Bert} />
        <p>
          Acuratețea preliminară a modelelor experimentate pe un set etichetat
          de 1000 de articole este prezentată în tabelul următor:
        </p>
        <table>
          <tr>
            <th>Model</th>
            <th>
              Min. Score <br /> (10 rulări)
            </th>
            <th>
              Max. Score <br /> (10 rulări)
            </th>
            <th>
              Mean Score <br /> (10 rulări)
            </th>
          </tr>
          <tr>
            <td>SVM</td>
            <td>
              <b>83.00</b>
            </td>
            <td>87.00</td>
            <td>85.05</td>
          </tr>
          <tr>
            <td>MNB</td>
            <td>72.50</td>
            <td>81.50</td>
            <td>76.60</td>
          </tr>
          <tr>
            <td>BERT</td>
            <td>82.50</td>
            <td>
              <b>88.00</b>
            </td>
            <td>
              <b>85.50</b>
            </td>
          </tr>
        </table>

      </div>
      <div className='Story'>
        <p>
          Cuvintele cu impact din analiza știrilor anterioare au fost
          următoarele:
        </p>
        <img alt='' src={WordCloud} />
        <p>
          În urma realizării activității etapei II a proiectului, aceeași
          secvență de procesare a fost aplicată asupra unui nou set de date cu
          650 de postări din platforma Twitter care conțin link-uri către
          articole de specialitate. Acuratețea preliminară a modelelor
          prezentate mai sus asupra noului set de date este prezentată în
          următorul tabel:
        </p>
        <table>
          <tr>
            <th>Model</th>
            <th>Acuratețe</th>
            <th>Scorul F1</th>
          </tr>
          <tr>
            <td>BERT</td>
            <td>
              <b>89.90%</b>
            </td>
            <td>
              <b>91.33%</b>
            </td>
          </tr>
          <tr>
            <td>SVM</td>
            <td>83.22%</td>
            <td>86.00%</td>
          </tr>
          <tr>
            <td>CNN</td>
            <td>68.98%</td>
            <td>75.24%</td>
          </tr>
        </table>

        <p>
          Etapa III a permis validarea secvenței de procesare într - un scenariu
          care simulează un mediu real . În acest sens a fost realizat un corpus
          cu articole extrase din 20 de website - uri de specialitate care a
          conținut 65.8 milioane de token - uri și un vocabular de 63.000 de
          cuvinte. În ceea ce privește validarea secvenței de procesare, noul
          corpus a permis măsurarea nivelului în care agregarea seturilor de
          date culese în etapele precedente din website - uri și platforma
          Twitter permit distingerea articolelor relevante pentru identificarea
          vulnerabilităților cibernetice timpurii de cele irelevante în nou
          corpus. Reprezentarea grafică a eficienței sistemului este vizibilă în
          imaginea de mai jos, care arată eficiența de clasificare a articolelor
          cu ( dreapta ) sau fără ( stânga ) setul de date agregat .
        </p>
        <img alt='' src={Efficiency} />
      </div>
    </div>
  )
}
