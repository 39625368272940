import * as React from 'react'

import './index.scss'

export interface IVisionProps {
  paragraphs: string[]
}

export default function Vision(props: IVisionProps) {
  return (
    <div className='Vision'>
      {props.paragraphs.map(paragraph => <p>{paragraph}</p>)}
    </div>
  )
}
