import './index.scss'

import * as React from 'react'

import { StatusType } from 'data/types'

import check from './assets/check.svg'
import inProgress from './assets/inProgress.svg'

export interface IBoxProps {
  title: string
  status?: StatusType
}

export default function Box(props: IBoxProps) {
  const Status = (
    <div className='Status'>
      {props.status === 'Completed' || props.status === 'Realizat'
        ? <img src={check} alt='' />
        : <img src={inProgress} alt='' />}
      <p>{props.status}</p>
    </div>
  )

  return (
    <div className='Box'>
      <h3>{props.title}</h3>
      {props.status ? Status : null}
    </div>
  )
}
