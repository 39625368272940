import * as React from 'react'

import { LanguageType } from 'data/types'

import Logo from './assets/logo.svg'

import './index.scss'

export interface IIntroProps {
  language: LanguageType
  onLanguageChange: (option: LanguageType) => void
}

export default function Intro(props: IIntroProps) {
  // const onOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const option = e.target.value

  //   if (option === 'english' || option === 'romanian') {
  //     props.onLanguageChange(option)
  //     localStorage.setItem('YGG_LANGUAGE', option)
  //   }
  // }

  return (
    <div className='Intro'>
      <img src={Logo} alt='' />
      <h2>Sistem Automat pentru Detectarea Timpurie a Vulnerabilităților Cibernetice</h2>
      {/* <select name='languages' onChange={onOptionChange}>
        <option value='english' selected={props.language === 'english'}>English</option>
        <option value='romanian' selected={props.language === 'romanian'}>Română</option>
      </select> */}
    </div>
  )
}
