import './index.scss'

import * as React from 'react'

import FootprintSideFeed from './assets/FootprintSideFeed.png'
import FootprintNotifications from './assets/FootprintNotifications.png'

import VideoPresentation from './assets/VideoPresentation.mp4'

export default function FootprintInterface() {
  return (
    <div className='FootprintInterface'>
      <div className='Story'>
        <p>
          În etapa III a proiectului au fost desfășurate activități pentru
          integrarea sistemului în platforma CODA Footprint. A fost decisă
          integrarea sub forma fluxului de știri (prima imagine) și a sistemului
          de alertare prin notificări în platformă (a doua imagine) și prin
          e-mail. Astfel, validarea de către un expert în securitatea
          cibernetică a unei vulnerabilități identificate de sistemul automat
          permite afișarea aces teia utilizatorilor CODA Footprint care dețin
          dispozitive ce pot fi afectate de amenințarea cibernetică respectivă.
        </p>
        <div className='Images'>
          <img src={FootprintSideFeed} alt='' />
          <img src={FootprintNotifications} alt='' />
        </div>
        <video src={VideoPresentation} controls />
      </div>
    </div>
  )
}
