import * as React from 'react'

import './index.scss'

import Scheme from './assets/Scheme.png'

export default function Architecture() {
  return (
    <div className='Architecture'>
      <p>
        Arhitectura sistemului pentru detectarea timpurie a vulnerabilităților
        cibernetice a fost introdusă în etapa a II-a a proiectului. Mai jos sunt
        ilustrate componentele cheie ale arhitecturii sistemului vizat.
      </p>
      <img src={Scheme} alt='' />
    </div>
  )
}
