import './index.scss'

import * as React from 'react'

export default function Dissemination() {
  return (
    <div className='Dissemination'>
      <div className='Story'>
        <p>
          Rezultate obținute au fost diseminate prin comunicări științifice:
        </p>
        <ul>
          <li>
            Iorga, D., Corlatescu D., Grigorescu, O., Sandescu C., Dascalu, M.,
            Rughinis, R. (2020) Early Detection of Vulnerabilities from News
            Websites using Machine Learning Models,{' '}
            <i>
              In 19th RoEduNet Conference: Networking in Education and Research
              (RoEduNet)
            </i>
            , Bucharest, Romania, 2020, pp. 6, doi:
            10.1109/RoEduNet51892.2020.9324852. (
            <a
              href='https://ieeexplore.ieee.org/document/9324852'
              rel='noopener noreferrer'
              target='_blank'
            >
              https://ieeexplore.ieee.org/document/9324852
            </a>
            )
          </li>
          <li>
            Radu, R., Săndescu, C., Grigorescu, O., Rughiniș, R. (submitted)
            Analyzing Risk Evaluation Frameworks and Risk Assessment Methods{' '}
            <i>
              In 19th RoEduNet Conference: Networking in Education and Research
              (RoEduNet)
            </i>
            , Bucharest, Romania, 2020, pp. 6, doi:
            10.1109/RoEduNet51892.2020.9324879 (
            <a
              href='https://ieeexplore.ieee.org/document/9324879'
              rel='noopener noreferrer'
              target='_blank'
            >
              https://ieeexplore.ieee.org/document/9324879
            </a>
            )
          </li>
          <li>
            Iorga, D., Corlătescu D., Grigorescu, O., Săndescu C., Dascălu, M.,
            Rughiniș, R. (in press) Yggdrasil – Early Detection of Cybernetic
            Vulnerabilities from Twitter. In 23rd Conference on Control Systems
            and Computer Science. Bucharest, Romania (Online): IEEE. (
            <a
              href='https://ieeexplore.ieee.org/document/9481044'
              rel='noopener noreferrer'
              target='_blank'
            >
              https://ieeexplore.ieee.org/document/9481044
            </a>
            )
          </li>
          <li>
            Frode de la Foret, P., Ruseti, S., Săndescu, C., Dascalu, M., {'&'}
            Travadel, S. (2021). Interpretable Identification of Cybersecurity
            Vulnerabilities from News Articles . In Int. Conf. on Recent
            Advances in Natural Language Processing (RANLP 2021) (pp.428 - 436).
            Varna, Bulgaria (Online): ACL.
          </li>
          <li>
            Iorga, D., Grigorescu, O., Predoiu, M., Săndescu, C ., Dascalu, M.,
            {'&'} Rughiniș, R. ( 2021 ). Early Usability Evaluation to Enhance
            User Interfaces – A Use Case on the Yggdrasil Cybersecurity Mockup
            –. In International Conference on Human - Computer Interaction
            (RoCHI2021). (pp.103 - 111) Bucharest, Romania (Online): MatrixRom.
          </li>
          <li>
            Babalau I, Corlatescu D.,Grigorescu O., Săndescu C., Dascălu, M. (in
            press) Severity Prediction of Software Vulnerabilities based on
            their Text Description In 2021 International Symposium on Symbolic
            and Numeric Algorithms for Scientific Computing (SYNASC 2021),
            Timișoara, România (Online)
          </li>
          <li>
            Săndescu C., Dinișor A., Vlădescu C - V, Grigorescu O., Corlătescu
            D., Dascălu M., Rughiniș R. (submitted) Extracting Exploits and
            Attack Vectors from cybersecurity news using NLP. In Scientific
            Bulletin, University Politehnica of Bucharest, Series C
          </li>
        </ul>
      </div>
    </div>
  )
}
