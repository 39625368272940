import './index.scss'

import * as React from 'react'

import Box from '../Box'

import { ResultType } from 'data/types'

export interface IResultsProps {
  results: ResultType[]
}

export default function Results(props: IResultsProps) {
  return (
    <div className='Results'>
      <div className='Grid'>
        {props.results.map((result) => (
          <Box title={result.text} status={result.status} />
        ))}
      </div>
      <div className='Stories'>
        <div className='Story'>
          <h2>Etapa I (2020)</h2>
          <p>
            În urma analizei algoritmilor automați pentru detectarea timpurie a
            vulnerabilităților cibernetice a fost realizat un set de date optim
            pentru dezvoltarea funcționalităților cheie aferente sistemului
            vizat.
          </p>
          <p>
            Analizând comparativ rezultatele obținute în urma aplicării
            tehnicilor de tip Machine Learning și Natural Language Processing
            asupra datelor vizate, s-a selectat ca metodă optimă pentru
            optimizarea prototipului existent un model de prelucrare a
            limbajului natural de tip Bidirectional Encoder Representations from
            Transformers (BERT).
          </p>
          <p>
            Experimentele inițiale de laborator au permis conceperea unui model
            cu o acuratețe de 88.45% în identificarea vulnerabilităților
            cibernetice din surse de date OSINT. Acest model reprezintă punctul
            de referință pentru viitoarele experimente și optimizări.
          </p>
        </div>
        <div className='Story'>
          <h2>Etapa II (Ianuarie - Aprilie 2021)</h2>
          <p>
            Etapa a presupus analiza și definirea surselor de date și a
            funcționalităților necesare pentru implementarea sistemului automat
            pentru detectarea timpurie a vulnerabilităților cibernetice.
            Totodată a fost realizat și un set de date adițional pentru
            optimizarea prototipului inițial.
          </p>
          <p>
            Analizând comparativ rezultatele obținute în urma aplicării
            tehnicilor de tip Machine Learning și Natural Language Processing
            asupra noului set de date, a fost confirmată alegerea modelului de
            prelucrare a limbajului natural de tip Bidirectional Encoder
            Representations from Transformers (BERT) pentru optimizarea
            prototipului existent.
          </p>
          <p>
            Noile experimente au permis conceperea unui prototip de predicție cu
            o acuratețe mare (aproximativ 90%) în identificarea
            vulnerabilităților cibernetice din surse de date OSINT. De asemenea,
            această etapă a permis identificarea funcționalităților de bază
            necesare sistemului vizat.
          </p>
        </div>
        <div className='Story'>
          <h2>Etapa III ( Mai - Noiembrie 2021 )</h2>
          <p>
            Îndeplinirea obiectivelor ultimei etape a permis dezvoltarea
            sistemului într - un produs cu un nivel de maturitate tehnologică
            TLR6. Au fost desfășurate activități pentru optimizarea și
            implementarea într - un mediu real a proceselor de extracție,
            analiză și a fișare a vulnerabilităților cibernetice timpurii din
            surse de date deschise.
          </p>
          <p>
            Dezvoltarea în laborator a sistemului a permis identificarea de noi
            surse de date deschise prin care pot fi îmbunătățite procesele de
            extracție, conceperea unui model interpreta bil prin care pot fi
            augmentate procesele de analiză a datelor și implementarea
            interfeței vizuale a sistemului prin raportare la feedbackul
            potențialilor utilizator.
          </p>
          <p>
            Validarea sistemului ca fiind operațional într - un mediu real a
            fost realizată prin int egrarea Yggdrasil în platforma CODA
            Footprint. A fost decisă și validată o integrare de tip HitAI
            („human in the loop Artificial Intelligence”) prin care
            vulnerabilitățile cibernetice identificate de sistem sunt validate
            de un expert în securitatea ciberne tică înainte de afișarea
            acestora în instanțele CODA Footprint.
          </p>
        </div>
      </div>
    </div>
  )
}
