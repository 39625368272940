import * as React from 'react'

import Header from './components/Header'
import Intro from './components/Intro'
import Menu from './components/Menu'
import Section from './components/Section'
import Vision from './components/Vision'
import Results from './components/Results'
import GeneralInformation from 'components/GeneralInformation'
import Architecture from 'components/Architecture'
import Sequence from './components/Sequence'
import Prototype from './components/Prototype'
import FootprintInterface from './components/FootprintInterface'
import Phases from './components/Phases'
import Footer from './components/Footer'
import Dissemination from './components/Dissemination'

import { getStrings } from './data/strings'
import { LanguageType, StringsObjectType } from './data/types'

import './App.scss'

export default function App() {
  const [language, setLanguage] = React.useState<LanguageType>('romanian')

  const stringsObject: StringsObjectType = getStrings(language)

  const onItemClick = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (
    window.localStorage.getItem('YGG_LANGUAGE') === 'romanian' &&
    language === 'english'
  ) {
    setLanguage('romanian')
  }

  return (
    <div className='App'>
      <Header language={language} />
      <Intro
        language={language}
        onLanguageChange={(option: LanguageType) => setLanguage(option)}
      />
      <Menu stringsObject={stringsObject} onItemClick={onItemClick} />
      <Section id='vision' title='Informații Generale' isRow>
        <GeneralInformation />
      </Section>
      <Section id='vision' title={stringsObject.visionTitle} isRow isReversed>
        <Vision paragraphs={stringsObject.vision} />
      </Section>
      <Section id='goals' title={stringsObject.goalsTitle} isRow>
        <Vision paragraphs={stringsObject.goals} />
      </Section>
      <Section id='results' title={stringsObject.resultsTitle}>
        <Results results={stringsObject.results} />
      </Section>
      <Section
        id='architecture'
        title='Arhitectura sistemului pentru  detectarea timpurie a vulnerabilităților din surse de date OSINT'
      >
        <Architecture />
      </Section>
      <Section
        id='sequence'
        title='Secvența de procesare pentru detectarea timpurie a vulnerabilităților din surse de date OSINT'
      >
        <Sequence />
      </Section>
      <Section
        id='prototype'
        title='Interfața cu utilizatorul sistemului pentru detectarea timpurie a vulnerabilităților din surse de date OSINT'
      >
        <Prototype />
      </Section>
      <Section
        id='prototype'
        title='Interfața utilizatorului Yggdrasil în CODA Footprint'
      >
        <FootprintInterface />
      </Section>
      <Section id='phases' title={stringsObject.phasesTitle}>
        <Phases phases={stringsObject.phases} />
      </Section>
      <Section id='dissemination' title='Diseminare'>
        <Dissemination />
      </Section>
      <Footer />
    </div>
  )
}
