import * as React from 'react'

import './index.scss'

export interface ISectionProps {
  id?: string
  title: string
  isRow?: boolean
  isReversed?: boolean
}

export default function Section(props: React.PropsWithChildren<ISectionProps>) {
  return (
    <div
      id={props.id}
      className={`Section ${props.isRow ? 'row' : ''} ${props.isReversed ? 'reversed' : ''}`}
    >
      <div className='Title'>
        <h1>{props.title}</h1>
      </div>
      {props.children}
    </div>
  )
}
